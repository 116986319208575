import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import "../../../../styles/main.css";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text sx={{
      mt: [4, 6],
      fontSize: [3, 4, 5],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi there. <span className="wave">👋</span>
    </Text>
    <p>{`I'm Andrew. Welcome to my web dev portfolio, where I post my side projects and (occasionally) publish my thoughts on tech-related topics.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      